import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AssessmentButton, Pagination, showToast } from "ui-components";
import AssessmentListingIcon from "../../utils/Images/AssessmentListingIcon";
import {
    capitalizeFirstLetter,
    convertDate,
    setInQueryParams,
    truncateString,
} from "../../utils/Common/Constant";
import EmailIcon from "../../utils/Images/EmailIcon";
import PhoneIcon from "../../utils/Images/PhoneIcon";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";
import CreditCoin from "../../utils/Images/CreditCoin";
import { partnerWithdrawalRequest } from "../../service/Partner/PartnerApis";

const WithdrawalRequest = () => {
    const { t } = useTranslation();
    const createdAssessPaginationRef = useRef<HTMLDivElement>(null);
    const [searchParams] = useSearchParams();
    const searchParam = searchParams.get("search") || "";
    const location = useLocation();
    const [withdrawalRequestLists, setwithdrawalRequestLists] = useState<any>([]);
    const navigate = useNavigate();
    const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
    const [totalCount, setTotalCount] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const timeoutRef = useRef<any>(null);
    const queryParams = new URLSearchParams(location.search);
    const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
    useEffect(() => {
        if (createdAssessPaginationRef.current && managePaginationRef != null) {
            createdAssessPaginationRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [managePaginationRef]);
    useEffect(() => {
        const debounceFunction = () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current as any);
            }
            timeoutRef.current = setTimeout(getwithdrawRequestApi, 500);
        };
        debounceFunction();
        return () => clearTimeout(timeoutRef.current);
    }, [location.search, searchParam, pageSize, page]);
    const getwithdrawRequestApi = async () => {
        try {
            const response = await partnerWithdrawalRequest(page, pageSize);
            if (response?.status === 200) {
                setwithdrawalRequestLists(response?.data?.data);
                setTotalCount(response?.data?.totalCount);
            } else {
                showToast(response?.data?.customMessage, "error");
            }
        } catch (error) {
            showToast(
                "An error occurred while fetching the companies list.",
                "error",
            );
        }
    };
    return (
        <>
            <div className="companyListWrapper CreatedAssessmentList">
                <div className="container-fluid mb-4">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="CreatedAssessmentListTitle">
                                <h2>{t("GENERAL.WITHDRAWAL_REQUEST")}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="totalContentDiv">
                        {totalCount > 0 && (
                            <p className="totalContent">
                                {t("GENERAL.TOTAL_WITHDRAWALS")} : {totalCount ?? "-"}
                            </p>
                        )}
                    </div>
                    <div className="row">
                        {withdrawalRequestLists?.length ? (
                            withdrawalRequestLists?.map((withdrawRequest: any) => {
                                return (
                                    <div className="AssessmentListContentSec" key={Math.random()}>
                                        <div className="AssessmentListCard">
                                            <div className="AssessmentListCardImg ">
                                                {withdrawRequest?.profilePic ? (
                                                    <div className="profileImage">
                                                        <img
                                                            src={withdrawRequest?.profilePic}
                                                            className="logo-img"
                                                        />
                                                    </div>
                                                ) : (
                                                    <AssessmentListingIcon />
                                                )}
                                                <h4 className="mt-3"> {withdrawRequest?.status}</h4>
                                            </div>
                                            <div className="AssessmentListCardDetails">
                                                <div className="ListCardDetailsTitleSec">
                                                    <div className="ListCardDetailsTitle">
                                                        <div className="listItemSec">
                                                            <div className="ListCardTitleHeading">
                                                                <div>
                                                                    <h3
                                                                        title={
                                                                            withdrawRequest?.displayName &&
                                                                            capitalizeFirstLetter(
                                                                                withdrawRequest?.displayName,
                                                                            )
                                                                        }
                                                                    >
                                                                        {withdrawRequest?.displayName
                                                                            ? capitalizeFirstLetter(
                                                                                truncateString(withdrawRequest?.displayName, 50)
                                                                            )
                                                                            : "-"}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                            <div className="email-mobile-section">
                                                                {withdrawRequest?.email && (
                                                                    <a
                                                                        href={`mailto:${withdrawRequest?.email}`}
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        <EmailIcon /> {withdrawRequest?.email}
                                                                    </a>
                                                                )}
                                                                &nbsp; &nbsp;
                                                                {withdrawRequest?.mobile && (
                                                                    <a href={`tel:${withdrawRequest?.mobile}`}>
                                                                        <PhoneIcon /> {withdrawRequest?.mobile}
                                                                    </a>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ListContentSkillsSec">
                                                    <div className="ListContentSkills decreaseGap">
                                                        <div className="w-100">
                                                            <div>
                                                                <p>{t("GENERAL.REQUESTED_CREDITS")}</p>
                                                                <h5 className="d-flex">
                                                                    {" "}
                                                                    <CreditCoin width={22} height={22} />
                                                                    {withdrawRequest?.requestedCredits ??
                                                                        withdrawRequest?.requestedCredits}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="w-100">
                                                            <div className="w-100">
                                                                <p>{t("GENERAL.CALCULATED_AMOUNT")}</p>
                                                                <h5>
                                                                    {withdrawRequest?.calculatedAmount ??
                                                                        withdrawRequest?.calculatedAmount}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                        <div className="w-100">
                                                            <div className="w-100">
                                                                <p>{t("GENERAL.REQUEST_DATE")}</p>
                                                                <h5>
                                                                    {withdrawRequest?.requestDate ? convertDate(withdrawRequest?.requestDate, false, true) :
                                                                        "-"}
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="RightSideArrowSec">
                                                        <AssessmentButton className="whiteBtn">
                                                            {t("GENERAL.APPROVE")}
                                                        </AssessmentButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <DataNotAvailable />
                        )}
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Pagination
                                className="pagination-bar"
                                currentPage={page ?? 1}
                                totalCount={totalCount || 0}
                                pageSize={pageSize}
                                onPageChange={(page: string) => {
                                    setInQueryParams(queryParams, "page", page, navigate);
                                    setManagePaginationRef(Math.random());
                                }}
                                ofLabel={t("GENERAL.OF")}
                                resultLabel={t("GENERAL.RESULTS")}
                                setPageSize={setPageSize}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WithdrawalRequest;
